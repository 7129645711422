import { Accordion, AccordionSummary, Box, Typography } from "@mui/material";
import React from "react";
import data from "../../data/Libraries.json";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CopyBlock, dracula } from "react-code-blocks";

export default function Libraries() {
  return (
    <Box
      display={"felx"}
      flexDirection={"column"}
      justifyContent={"center"}
      color={"black"}
    >
      <Box
        sx={{
          padding: 1,
          borderRadius: 1,
          color: "black",
          backgroundColor: "#c4c4c4",
        }}
      >
        {data.map((n) => {
          return (
            <>
              <Accordion
                sx={{
                  backgroundColor: "#c4c4c4",
                  color: "black",
                  borderRadius: 5,
                }}
              >
                <AccordionSummary
                  sx={{
                    backgroundColor: "#b0b0b0",
                    color: "black",
                  }}
                  expandIcon={<ExpandMoreIcon sx={{ color: "black" }} />}
                >
                  <Typography variant="h7">{n.name}</Typography>
                </AccordionSummary>
                <Box
                  margin={2}
                  sx={{
                    padding: 1,
                    marginBottom: 1,
                  }}
                >
                  {n.code.map((a) => {
                    return (
                      <Box
                        sx={{
                          padding: 1,
                          marginBottom: 1,
                          width: "55vw",
                          fontSize: "11px",
                        }}
                      >
                        <CopyBlock
                          text={a}
                          language="cs"
                          codeBlock
                          theme={dracula}
                        />
                      </Box>
                    );
                  })}

                  <Typography marginTop={3}>{n.description}</Typography>
                  <a marginTop={2} marginBottom={2} href={n.nugetLink}>
                    {n.nugetLink}
                  </a>
                </Box>
              </Accordion>
            </>
          );
        })}
      </Box>
    </Box>
  );
}
