import { Box, Typography } from "@mui/material";
import React from "react";
import data from "../../data/CollegeProjects.json";

export default function DegreeProjects() {
  return (
    <Box
      display={"felx"}
      flexDirection={"column"}
      justifyContent={"center"}
      color={"black"}
    >
      <Box
        sx={{
          padding: 1,
          borderRadius: 1,
          color: "black",
          backgroundColor: "#c4c4c4",
        }}
      >
        {data.map((n) => {
          return (
            <>
              <Box
                sx={{ border: 1, borderColor: "black", padding: 1, margin: 3 }}
              >
                <Box marginBottom={1}>
                  <Typography variant="h5">{n.name}</Typography>
                  <Typography>{n.semester}</Typography>
                </Box>
                <Typography marginBottom={2}>{n.description}</Typography>
                <Typography marginBottom={2}>
                  Stack :{n.stack.join(", ")}
                </Typography>
                {n.keyFeatures?.map((a) => {
                  return <Typography>• {a}</Typography>;
                })}
              </Box>
            </>
          );
        })}
      </Box>
    </Box>
  );
}
