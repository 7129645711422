import { Box, Typography } from "@mui/material";
import React from "react";

export default function AboutMe() {
  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        color={"black"}
      >
        <Box sx={{ backgroundColor: "#c4c4c4", padding: 5, borderRadius: 5 }}>
          <Typography variant="h5">About Me</Typography>
          <Typography>
            I have been engaged in the world of computers ever since I was very
            young. Those days, I would play the few games I had over and over
            again. Now and then, something would break. Maybe I installed
            something or deleted something I shouldn't have. I had a choice
            then. Let it stay broken or...
          </Typography>
          <Typography marginTop={5}>
            I was introduced to coding in high school. I was hooked instantly.
            After all these years of breaking, tinkering, and fixing things in
            my system, I am now introduced to the possibility of making
            something on my own. I have to thank my high school CS teachers,
            Khan Academy, YouTube, and StackOverflow for fueling my coding
            journey.
          </Typography>
          <Typography marginTop={5}>
            I don't want to write my whole biography here. But I hope you get a
            grasp of how I came to be from what little I have said above. Now
            let's cut to the chase. What can I offer you as a developer?
          </Typography>
          <Typography marginTop={1}>
            I have a firm grasp of and experience in all aspects of the software
            development life cycle. This is because I have closely worked with
            the management of my past employment and my experience as a
            freelance developer. I have experience working on projects of
            various scales as a solo developer as well as a member of a team. I
            regularly keep myself up-to-date on my stack and introduce new
            technologies to it. I am a strong candidate for software development
            roles, which require a wider understanding and holistic
            responsibility.
          </Typography>
        </Box>
      </Box>
    </>
  );
}
