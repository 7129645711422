import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Box } from "@mui/material";


export default function ImageViewer(props) {
    return (
        <>
          <Dialog open={props.show} maxWidth>
            <DialogContent maxWidth>
                <Box component="img" src={props.src} width={"50vw"}/>
            </DialogContent>
            <DialogActions sx={{ backgroundColor: "#c4c4c4" }}>
              <Button variant="contained" onClick={props.close}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
}
