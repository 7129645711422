import { Box, Typography } from "@mui/material";
import React from "react";
import data from "../../data/Hobbies.json";

export default function Hobbies() {
  return (
    <Box
      display={"felx"}
      flexDirection={"column"}
      justifyContent={"center"}
      color={"black"}
    >
      <Box
        sx={{
          padding: 1,
          borderRadius: 1,
          color: "black",
          backgroundColor: "#c4c4c4",
        }}
      >
        {
            data.map((n)=>{
                return (
                    <>
                    <Box
                sx={{ border: 1, borderColor: "black", padding: 1, margin: 3 }}
              >
                <Box>
                    <Typography variant="h5">{n.name}</Typography>
                    <Typography>{n.description}</Typography>
                </Box>
              </Box>
                    </>
                );
            })
        }
      </Box>
    </Box>
  );
}
