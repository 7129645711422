import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL;

export async function ApiGet(path) {
  var url = baseUrl + path;
  return await axios
    .get(url)
    .then(({ data }) => {
    })
    .catch(async (error) => {
    });
}

export async function ApiDelete(path) {
  var url = baseUrl + path;
  return await axios
    .delete(url)
    .then(({ data }) => {
      
    })
    .catch(async (error) => {
      
    });
}

export async function ApiPost(path, data) {
  var url = baseUrl + path;
  return await axios
    .post(url, data)
    .then(({ data }) => {
      
    })
    .catch(async (error) => {
      
    });
}

export async function ApiPut(path, data) {
  var url = baseUrl + path;
  return await axios
    .put(url, data)
    .then(({ data }) => {
      
    })
    .catch(async (error) => {
      
    });
}
