import { Accordion, AccordionSummary, Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import data from "../../data/Projects.json";
import ImageViewer from "../../components/ImageViewer";

export default function Projects() {
  const [image, setImage] = useState({
    show: false,
    src: null,
  });
  return (
    <>
      <ImageViewer
        show={image.show}
        src={image.src}
        close={() => setImage({ show: false, src: null })}
      />
      <Box
        display={"felx"}
        flexDirection={"column"}
        justifyContent={"center"}
        color={"black"}
      >
        <Box
          sx={{
            padding: 1,
            borderRadius: 1,
            color: "black",
            backgroundColor: "#c4c4c4",
          }}
        >
          <Typography margin={1}>
            Projects are in accending order of when each was created
          </Typography>
          {data.map((n) => {
            return (
              <>
                <Accordion
                  sx={{
                    backgroundColor: "#c4c4c4",
                    color: "black",
                    borderRadius: 5,
                  }}
                >
                  <AccordionSummary
                    sx={{
                      backgroundColor: "#b0b0b0",
                      color: "black",
                    }}
                    expandIcon={<ExpandMoreIcon sx={{ color: "black" }} />}
                  >
                    <Typography variant="h7">{n.name}</Typography>
                  </AccordionSummary>
                  <Box
                    margin={2}
                    sx={{
                      padding: 1,
                      marginBottom: 1,
                    }}
                  >
                    <Typography>Project For : {n.for}</Typography>
                    {(n.images?.length ?? 0) != 0 && (
                      <Grid
                        container
                        sx={{
                          border: 1,
                          borderColor: "black",
                          padding: 1,
                          marginBottom: 1,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                        }}
                      >
                        {n.images.map((a) => {
                          return (
                            <>
                              <Grid xs={4} margin={1}>
                                <Box
                                  component="img"
                                  src={a}
                                  width="200px"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    setImage({ show: true, src: a })
                                  }
                                />
                              </Grid>
                            </>
                          );
                        })}
                      </Grid>
                    )}
                    <Typography marginTop={3}>{n.description}</Typography>
                    <Typography marginTop={2} marginBottom={2}>
                      Stack: {n.stack.join(", ")}
                    </Typography>
                    {n.keyFeatures?.map((a) => {
                      return <Typography>• {a}</Typography>;
                    })}
                  </Box>
                </Accordion>
              </>
            );
          })}
        </Box>
      </Box>
    </>
  );
}
