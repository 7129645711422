import { Box, Typography } from "@mui/material";
import React from "react";
import data from "../../data/Eduction.json";

export default function Education() {
  return (
    <Box
      display={"felx"}
      flexDirection={"column"}
      justifyContent={"center"}
      color={"black"}
    >
      <Box
        sx={{
          padding: 1,
          borderRadius: 1,
          color: "black",
          backgroundColor: "#c4c4c4",
        }}
      >
        {data.map((n) => {
          return (
            <>
              <Box
                sx={{ border: 1, borderColor: "black", margin: 5, padding: 1 }}
              >
                <Box sx={{ marginBottom: 3 }}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Typography variant="h5">{n.course}</Typography>
                    <Typography>
                      {n.from} - {n.to}
                    </Typography>
                  </Box>
                  <Typography>{n.institution}</Typography>
                  <Typography>{n.location}</Typography>
                </Box>
                <Typography>{n.description}</Typography>
              </Box>
            </>
          );
        })}
      </Box>
    </Box>
  );
}
