import "../App.css";
import { Typography, Box } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useRef } from "react";
import useScrollSnap from "react-use-scroll-snap";
import GetVisiterInfo from "./GetVisiterInfo";
import { v4 as uuidv4 } from "uuid";
import Resume from "./Resume";
import { ApiPost, ApiPut } from "../services/ApiService";

export default function Main() {
  const ref1 = useRef(null);//hello
  const [id, setId] = useState(null);
  const [continuePressed, setContinuePressed] = useState(false);
  const [continueData, setContinueData] = useState({
    visitorType: null,
    objective: null,
    company: null,
    contactInfo: null,
  });
  const isInViewport1 = useIsInViewport(ref1);

  useEffect(() => {
    var _id = localStorage.getItem("id");
    if (_id == null || _id == "" || _id == "null") {
      _id = uuidv4();
      setId(_id);
      localStorage.setItem("id", _id);
    } else {
      setId(_id);
    }

    const _continuePressed = localStorage.getItem("continue");
    if (
      _continuePressed == null ||
      _continuePressed == "" ||
      _continuePressed == "null"
    ) {
      setContinuePressed(false);
    } else {
      setContinuePressed(true);
    }
  }, []);

  useEffect(() => {
    if (id != null) {
      ApiPost("Visitors", { id: id });
    }
  }, [id]);

  const continueClicked = async () => {
    localStorage.setItem("continue", true);
    setContinuePressed(true);
    await ApiPut("Visitors/" + id, continueData);
  };

  return (
    <div>
      <div>
        <div
          className="App-header"
          style={{
            background: "rgb(0,0,0)",
            background:
              "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(196,196,196,1) 100%)",
          }}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Typography variant="h1">Salman Sali</Typography>
            <Box
              height={250}
              width={35}
              margin={20}
              backgroundColor="black"
              ref={ref1}
            ></Box>
            <Box display={"flex"} flexDirection={"column"}>
              <Typography variant="h4" margin={1}>
                Full Stack
              </Typography>
              <Typography variant="h4" margin={1}>
                DevOps
              </Typography>
            </Box>
          </Box>
        </div>
        {!continuePressed && (
          <div className="App-header" style={{ backgroundColor: "black" }}>
            <GetVisiterInfo
              continueClicked={continueClicked}
              valuesChanged={(v) => setContinueData(v)}
            />
          </div>
        )}
        {continuePressed && (
          <div
            className="App-header"
            style={{
              background: "rgb(0,0,0)",
              background:
                "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(196,196,196,1) 100%)",
            }}
          >
            <Resume isInViewport1={isInViewport1} />
          </div>
        )}
      </div>
    </div>
  );
}

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}
