import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";

export default function ResumeIndex(props) {
  const [item, setItem] = useState("About Me");

  useEffect(() => {
    props.clicked(item);
  }, [item]);

  return (
    <>
      <Box
        sx={{
          visibility: props.isInViewport1 ? "hidden" : "visible",
          height: "95vh",
          width: "300px",
          padding: 5,
          position: "fixed",
          top: "2%",
          left: "1%",
          borderRadius: 5,
          backgroundColor: "black",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <Button
          variant={item == "About Me" ? "contained" : "text"}
          sx={{ textTransform: "none" }}
          onClick={() => setItem("About Me")}
        >
          About Me
        </Button>
        <Button
          variant={item == "Contact Me" ? "contained" : "text"}
          sx={{ textTransform: "none" }}
          onClick={() => setItem("Contact Me")}
        >
          Contact Me
        </Button>
        <Button
          variant={item == "Socials" ? "contained" : "text"}
          sx={{ textTransform: "none" }}
          onClick={() => setItem("Socials")}
        >
          Socials
        </Button>
        <Button
          variant={item == "Employment" ? "contained" : "text"}
          sx={{ textTransform: "none" }}
          onClick={() => setItem("Employment")}
        >
          Employement
        </Button>
        <Button
          variant={item == "Projects" ? "contained" : "text"}
          sx={{ textTransform: "none" }}
          onClick={() => setItem("Projects")}
        >
          Projects
        </Button>
        <Button
          variant={item == "Prototypes" ? "contained" : "text"}
          sx={{ textTransform: "none" }}
          onClick={() => setItem("Prototypes")}
        >
          Prototypes
        </Button>
        <Button
          variant={item == "Libraries" ? "contained" : "text"}
          sx={{ textTransform: "none" }}
          onClick={() => setItem("Libraries")}
        >
          Libraries
        </Button>
        <Button
          variant={item == "Skills" ? "contained" : "text"}
          sx={{ textTransform: "none" }}
          onClick={() => setItem("Skills")}
        >
          Skills
        </Button>
        <Button
          variant={item == "Education" ? "contained" : "text"}
          sx={{ textTransform: "none" }}
          onClick={() => setItem("Education")}
        >
          Education
        </Button>
        <Button
          variant={item == "Degree Projects" ? "contained" : "text"}
          sx={{ textTransform: "none" }}
          onClick={() => setItem("Degree Projects")}
        >
          Degree Projects
        </Button>{" "}
        <Button
          variant={item == "Hobbies" ? "contained" : "text"}
          sx={{ textTransform: "none" }}
          onClick={() => setItem("Hobbies")}
        >
          Hobbies
        </Button>
        <Button
          variant={item == "This Website's Stack" ? "contained" : "text"}
          sx={{ textTransform: "none" }}
          onClick={() => setItem("This Website's Stack")}
        >
          This Website's Stack
        </Button>
      </Box>
    </>
  );
}
