import { Box, Typography } from "@mui/material";
import React from "react";

export default function Socials() {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      color={"black"}
    >
      <Box sx={{ backgroundColor: "#c4c4c4", padding: 5, borderRadius: 5 }}>
        <Box sx={{ marginBottom: 10 }}>
          <Typography variant="h5">Github</Typography>
          <a href="https://github.com/Salman-Sali">
            https://github.com/Salman-Sali
          </a>
          <Box sx={{ backgroundColor: "black", padding: 1, borderRadius: 1 }}>
            <img
              src="https://ghchart.rshah.org/Salman-Sali"
              alt="Name Your Github chart"
            />
          </Box>
        </Box>

        <Box sx={{ marginBottom: 10 }}>
          <Typography variant="h5">Linkedin</Typography>
          <a href="https://www.linkedin.com/in/salman-sali/">
            https://www.linkedin.com/in/salman-sali/
          </a>
        </Box>

        <Box sx={{ marginBottom: 10 }}>
          <Typography variant="h5">Stack Overflow</Typography>
          <a href="https://stackoverflow.com/users/8480538/salman-sali">
            https://stackoverflow.com/users/8480538/salman-sali
          </a>
        </Box>
      </Box>
    </Box>
  );
}
