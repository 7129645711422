import { Box, Typography } from "@mui/material";
import React from "react";
import data from "../../data/EmploymentHistory.json";

export default function Employment() {
  return (
    <>
      <Box
        display={"felx"}
        flexDirection={"column"}
        justifyContent={"center"}
        color={"black"}
      >
        <Box sx={{ backgroundColor: "#c4c4c4", padding: 5, borderRadius: 5 }}>
          {data.map((n) => {
            return (
              <>
                <Box
                  margin={2}
                  sx={{
                    border: 1,
                    borderColor: "balck",
                    padding: 1,
                    marginBottom: 1,
                  }}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Typography variant="h7">{n.role}</Typography>
                    <Typography marginLeft={5}>
                      {n.to && n.from && n.to + " - " + n.from}
                    </Typography>
                  </Box>
                  <Typography>{n.company}</Typography>
                  <Typography marginTop={2}>{n.description}</Typography>
                </Box>
              </>
            );
          })}
        </Box>
      </Box>
    </>
  );
}
