import React, { useState } from "react";
import ResumeIndex from "./ResumeIndex";
import { Box, Typography } from "@mui/material";
import AboutMe from "./ResumeElements/AboutMe";
import Employment from "./ResumeElements/Employment";
import Projects from "./ResumeElements/Projects";
import ContactMe from "./ResumeElements/ContactMe";
import Socials from "./ResumeElements/Socials";
import Prototypes from "./ResumeElements/Prototypes";
import Libraries from "./ResumeElements/Libraries";
import Education from "./ResumeElements/Education";
import DegreeProjects from "./ResumeElements/DegreeProjects";
import Hobbies from "./ResumeElements/Hobbies";
import Skills from "./ResumeElements/Skills";
import WebsiteStack from "./ResumeElements/WebsiteStack";

export default function Resume(props) {
  const [item, setItem] = useState("About Me");

  return (
    <>
      <ResumeIndex
        clicked={(item) => setItem(item)}
        isInViewport1={props.isInViewport1}
      />
      <Box
        display={"flex"}
        flexDirection={"column"}
        marginLeft={"30vw"}
        marginRight={"5vw"}
        marginTop={"5vh"}
      >
        {item == "About Me" && <AboutMe />}
        {item == "Contact Me" && <ContactMe />}
        {item == "Socials" && <Socials />}
        {item == "Employment" && <Employment />}
        {item == "Projects" && <Projects />}
        {item == "Prototypes" && <Prototypes />}
        {item == "Libraries" && <Libraries />}
        {item == "Education" && <Education />}
        {item == "Degree Projects" && <DegreeProjects />}
        {item == "Hobbies" && <Hobbies/>}
        {item == "Skills" && <Skills/>}
        {item == "This Website's Stack" && <WebsiteStack/>}
      </Box>
    </>
  );
}
