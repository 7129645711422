import React from "react";
import data from "../../data/Skills.json";
import { Accordion, AccordionSummary, Box, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { range } from "lodash";

export default function Skills() {
  return (
    <>
      <Box
        display={"felx"}
        flexDirection={"column"}
        justifyContent={"center"}
        color={"black"}
      >
        <Box
          sx={{
            padding: 1,
            borderRadius: 1,
            color: "black",
            backgroundColor: "#c4c4c4",
          }}
        >
          <Typography sx={{ margin: 1 }}>
            Stars indicate level of experience
          </Typography>
          {data.map((n) => {
            return (
              <>
                <Accordion sx={{ backgroundColor: "#c4c4c4", color: "black" }}>
                  <AccordionSummary sx={{ backgroundColor:"#b0b0b0"}}>{n.type}</AccordionSummary>
                  <Box padding={1}>
                    {n.items.map((a) => {
                      return (
                        <>
                          <Box
                            sx={{
                              border: 1,
                              borderColor: "black",
                              padding: 1,
                              margin: 1,
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography>{a.name}</Typography>
                            <Box>
                              {range(a.stars).map((aa) => {
                                return <StarIcon />;
                              })}
                            </Box>
                          </Box>
                        </>
                      );
                    })}
                  </Box>
                </Accordion>
              </>
            );
          })}
        </Box>
      </Box>
    </>
  );
}
