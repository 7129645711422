import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function GetVisiterInfo(props) {
  const [youAre, setYouAre] = useState(null);
  const [continueData, setContinueData] = useState({
    visitorType: null,
    objective: null,
    company: null,
    contactInfo: null,
  });

  useEffect(() => {
    setContinueData((prv) => ({ ...prv, visitorType: youAre }));
  }, [youAre]);

  const continueClicked = () => {
    props.continueClicked();
  };

  useEffect(()=>{
    props.valuesChanged(continueData);
  }, [continueData]);

  return (
    <>
      <div>
        <Typography color={"white"} variant="h3">
          Tell Me About You
        </Typography>
        <Typography color={"white"}>All fields are optional.</Typography>

        <Box
          margin={10}
          marginBottom={1}
          sx={{ border: 1, borderColor: "white" }}
          padding={2}
          width={"100vh"}
          height={"55vh"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Typography variant="h7" color={"white"} margin={3} width={"200px"}>
              You are :
            </Typography>
            <Button
              variant={youAre == "Recruiter" ? "contained" : "outlined"}
              sx={{
                color: youAre == "Recruiter" ? "black" : "white",
                height: "50px",
                textTransform: "none",
                margin: "10px",
              }}
              onClick={() => setYouAre("Recruiter")}
            >
              <Typography variant="h5">Recruiter</Typography>
            </Button>

            <Button
              variant={youAre == "Client" ? "contained" : "outlined"}
              sx={{
                color: youAre == "Client" ? "black" : "white",
                height: "50px",
                textTransform: "none",
                margin: "10px",
              }}
              onClick={() => setYouAre("Client")}
            >
              <Typography variant="h5">Client</Typography>
            </Button>

            <Button
              variant={youAre == "Other" ? "contained" : "outlined"}
              sx={{
                color: youAre == "Other" ? "black" : "white",
                height: "50px",
                textTransform: "none",
                margin: "10px",
              }}
              onClick={() => setYouAre("Other")}
            >
              <Typography variant="h5">Other</Typography>
            </Button>
          </Box>

          {(youAre == "Recruiter" || youAre == "Client") && (
            <Box display={"flex"} alignItems={"center"}>
              <Typography
                variant="h7"
                color={"white"}
                margin={3}
                width={"200px"}
              >
                Company :
              </Typography>
              <TextField
                variant="standard"
                inputProps={{ sx: { color: "#fff" }, borderColor: "white",  maxLength: 40 }}
                sx={{
                  border: "1px",
                  borderColor: "white",
                  borderBlockColor: "white",
                  borderBlock: "1px",
                  width: "400px",
                }}
                onChange={(e) =>
                  setContinueData((prv) => ({
                    ...prv,
                    company: e.target.value,
                  }))
                }
              />
            </Box>
          )}

          <Box display={"flex"} alignItems={"center"}>
            <Typography variant="h7" color={"white"} margin={3} width={"200px"}>
              Objective :
            </Typography>
            <TextField
              variant="standard"
              inputProps={{ sx: { color: "#fff" }, borderColor: "white",  maxLength: 30 }}
              sx={{
                border: "1px",
                borderColor: "white",
                borderBlockColor: "white",
                borderBlock: "1px",
                width: "400px",
              }}
              onChange={(e) =>
                setContinueData((prv) => ({
                  ...prv,
                  objective: e.target.value,
                }))
              }
            />
          </Box>

          <Box display={"flex"} alignItems={"center"}>
            <Typography variant="h7" color={"white"} margin={3} width={"200px"}>
              Contact Info :
            </Typography>
            <TextField
              variant="standard"
              inputProps={{ sx: { color: "#fff" }, borderColor: "white",  maxLength: 50 }}
              sx={{
                border: "1px",
                borderColor: "white",
                borderBlockColor: "white",
                borderBlock: "1px",
                width: "400px",
              }}
              onChange={(e) =>
                setContinueData((prv) => ({
                  ...prv,
                  contactInfo: e.target.value,
                }))
              }
            />
          </Box>
        </Box>

        <Box display={"flex"} justifyContent={"center"}>
          <Button variant="outlined" onClick={continueClicked}>
            Continue
          </Button>
        </Box>
      </div>
    </>
  );
}
