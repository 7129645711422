import React from "react";
import data from "../../data/Prototypes.json";
import { Box, Typography, Accordion, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Prototypes() {
  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        color={"black"}
      >
        <Box sx={{ backgroundColor: "#c4c4c4", padding: 5, borderRadius: 5 }}>
          {data.map((n) => {
            return (
              <>
                <Accordion
                  sx={{
                    backgroundColor: "#c4c4c4",
                    color: "black",
                    borderRadius: 5,
                  }}
                >
                  <AccordionSummary
                    sx={{
                      backgroundColor: "#b0b0b0",
                      color: "black",
                    }}
                    expandIcon={<ExpandMoreIcon sx={{ color: "black" }} />}
                  >
                    <Typography variant="h7">{n.name}</Typography>
                  </AccordionSummary>
                  <Box
                    margin={2}
                    sx={{
                      padding: 1,
                      marginBottom: 1,
                    }}
                  >
                    <Typography>Prototype For : {n.for}</Typography>
                    <Typography marginTop={3}>{n.description}</Typography>
                    <Typography marginTop={2} marginBottom={2}>
                      Stack: {n.stack.join(", ")}
                    </Typography>
                    {n.keyFeatures?.map((a) => {
                      return <Typography>• {a}</Typography>;
                    })}
                  </Box>
                </Accordion>
              </>
            );
          })}
        </Box>
      </Box>
    </>
  );
}
