import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ApiPut } from "../../services/ApiService";

export default function ContactMe() {
  const [messageSent, setMessageSent] = useState(false);
  const [data, setData] = useState({
    name: null,
    contactInfo: null,
    messageBody: null,
  });

  useEffect(() => {
    var sent = localStorage.getItem("messageSent");
    if (sent == null || sent == "" || sent == "null") {
      setMessageSent(false);
    } else {
      setMessageSent(true);
    }
  }, []);

  const send = async () => {
    setMessageSent(true);
    localStorage.setItem("messageSent", true);
    await ApiPut("Visitors/" + localStorage.getItem("id") + "/Message", data);
  };

  return (
    <>
      <Box justifyContent={"center"} display={"felx"} color={"black"}>
        <Box
          sx={{
            padding: 1,
            borderRadius: 1,
            color: "black",
            backgroundColor: "#c4c4c4",
          }}
        >
          <Box sx={{ padding: 1, margin: 2 }}>
            <Typography variant="h5" margin={2}>
              Email: contact@salmansali.com
            </Typography>
            <Typography variant="h5" margin={2} marginBottom={5}>
              Whatsapp: +91 89430 91727
            </Typography>

            <Box
              sx={{
                backgroundColor: "black",
                color: "white",
                padding: 5,
                borderRadius: 5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {messageSent ? (
                <Typography>
                  Your message has been delivered. Please email or WhatsApp me
                  for further contact.
                </Typography>
              ) : (
                <>
                  <TextField
                    sx={{ marginBottom: 4 }}
                    label="Name"
                    variant="outlined"
                    inputProps={{
                      autoComplete: "disabled",
                      maxLength: 50,
                    }}
                    onChange={(e) =>
                      setData((prv) => ({ ...prv, name: e.target.value }))
                    }
                  />
                  <TextField
                    inputProps={{
                      autoComplete: "disabled",
                      maxLength: 50,
                    }}
                    sx={{ marginBottom: 4 }}
                    label="Contact Info"
                    onChange={(e) =>
                      setData((prv) => ({
                        ...prv,
                        contactInfo: e.target.value,
                      }))
                    }
                  />
                  <TextField
                    inputProps={{
                      autoComplete: "disabled",
                      maxLength: 200,
                    }}
                    sx={{ marginBottom: 4 }}
                    label="Your Message"
                    multiline
                    minRows={3}
                    onChange={(e) =>
                      setData((prv) => ({
                        ...prv,
                        messageBody: e.target.value,
                      }))
                    }
                  />
                  <Button onClick={send}>Send</Button>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
