import { Box, Typography } from "@mui/material";
import React from "react";

export default function WebsiteStack() {
  return (
    <Box
      display={"felx"}
      flexDirection={"column"}
      justifyContent={"center"}
      color={"black"}
    >
      <Box
        sx={{
          padding: 1,
          borderRadius: 1,
          color: "black",
          backgroundColor: "#c4c4c4",
        }}
      >
        <Box>
          <Box sx={{ margin: 3, display: "flex", flexDirection: "row", alignItems:"center" }}>
            <Typography variant="h5">Frontend :</Typography>
            <Typography variant="h7" fontWeight={"bold"}>
              &nbsp;ReactJs
            </Typography>
          </Box>
          <Box sx={{ margin: 3, display: "flex", flexDirection: "row", alignItems:"center" }}>
            <Typography variant="h5">Frontend Hosting :</Typography>
            <Typography variant="h7" fontWeight={"bold"}>
              &nbsp;AWS Amplify (S3)
            </Typography>
          </Box>
          <Box sx={{ margin: 3, display: "flex", flexDirection: "row", alignItems:"center" }}>
            <Typography variant="h5">Backend :</Typography>
            <Typography variant="h7" fontWeight={"bold"}>
              &nbsp;C# .Net 6 WebApi
            </Typography>
          </Box>
          <Box sx={{ margin: 3, display: "flex", flexDirection: "row", alignItems:"center" }}>
            <Typography variant="h5">Backend Hosting :</Typography>
            <Typography variant="h7" fontWeight={"bold"}>
              &nbsp;Aws Lambda
            </Typography>
          </Box>
          <Box sx={{ margin: 3, display: "flex", flexDirection: "row", alignItems:"center" }}>
            <Typography variant="h5">Database :</Typography>
            <Typography variant="h7" fontWeight={"bold"}>
              &nbsp;DynamoDb
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
